import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, LoadingController, MenuController, Platform } from '@ionic/angular';
import { LoginService } from './services/login/login.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  language: any;
  public appPages = [
    { title: this.translate.instant('ChoiceStudio'), url: '/reservation', icon: 'book' },
    { title: this.translate.instant('mondossier'), url: '/mon-dossier', icon: 'folder' },
    { title: this.translate.instant('macandid'), url: '/ma-candidature', icon: 'person' }
  ];

  constructor( private alertController:AlertController, 
    private authService:LoginService,private loadingController:LoadingController ,
     private platform: Platform,
     public translate: TranslateService,
     private menuController:MenuController,
     private router:Router
   ) {
      
      translate.addLangs(['en', 'fr']);
      const browserLang = translate.getBrowserLang();
        this.translate.use("en");
        this.translate.setDefaultLang("fr");

      if(browserLang == "fr"){
        this.translate.use("fr");
        this.translate.setDefaultLang("fr");
      }else {
        this.translate.use("en");
        this.translate.setDefaultLang("en");
      }
    }



  ngOnInit(): void {
    this.language = this.translate.getDefaultLang();
    this.menuController.enable(false)
  }

  initializeApp() {
  

    this.platform.ready().then(() => {
        
    
    });
  }
  hoverlang(){   
		var coursesnav = document.querySelector('.blocLang')
		coursesnav.classList.add('translated')
	  }
	  clicklang(){   
		var coursesnav = document.querySelector('.blocLang')
		coursesnav.classList.toggle('translated')
	  }
	  leavelang(){
		var coursesnav = document.querySelector('.blocLang')
		coursesnav.classList.remove('translated')   
	  }
	onChange(lang) {
		this.language = lang;
		this.translate.use(this.language);	  
	}

  async logout() {
    const alert = await this.alertController.create({
      header: this.translate.instant('deconnexion'),
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'primary',
          handler: () => { 
          }
        }, {
          text: 'Ok',
          cssClass: 'secondary',
          handler: () => {
            this.logoutAction()
          }
        }
      ]
    });
    await alert.present();
  }


 async logoutAction()
  {
    const loading = await this.loadingController.create({});
    await loading.present();
    this.authService.logoutUser()
      .then(async (res) => {
        this.router.navigateByUrl("/login");
        loading.dismiss();
      })
      .catch((error) => {
        loading.dismiss();
      })
  }
}
