import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(public db: AngularFireDatabase,
    public afAuth: AngularFireAuth) { }

  addUser(idUser, data) {
    if(idUser && data){
      return this.db.list('users').update(idUser, data);
    }
  }

  signup(data: any) {
    return this.afAuth.createUserWithEmailAndPassword(data.email, data.password).then(value => {
      data.token = value.user.uid;
      this.addUser(value.user.uid, data);
      return 'Client Ajouté avec succés';
    }).catch(err => {
      return err.message;
    });
  }

  UpdateUser(UserKey, data) {
    if(UserKey && data){
      return this.db.list('users').update(UserKey, data);
    }    
  }
  
  addDossier(UserKey, data){
    if(UserKey && data){
      return this.db.list('users/'+UserKey+"/Dossier").push(data);
    }    
  }
  getUser(UserKey){
    return this.db.object('users/'+UserKey).valueChanges();
   }  

   getUserUid() {
    return this.afAuth.currentUser;
  }

  loginUser(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password)
  }

  createCandidatureToUser(idUser, data){
    return this.db.list('users/'+idUser+'/candidature').push(data);
  }

  createCandidature(idUser,data)
  {
    return this.db.list('candidatures').update(idUser,data);
  }


  getCandidatureUser(key)
  {
    return this.db.object('candidatures/'+key).valueChanges();
  }

  resetPassword(email: string) {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  logoutUser():Promise<void> {
    return this.afAuth.signOut();     
  }


}

