import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/userService/auth.guard';
const routes: Routes = [
    {
      path: '',
      redirectTo: '/reservation',
      pathMatch: 'full'
      },
        {
          path: 'login',
          loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
        },
        {
          path: 'reservation',
          loadChildren: () => import('./reservation/reservation.module').then( m => m.ReservationPageModule)    
        },
        {
          path: 'mon-dossier',
          loadChildren: () => import('./mon-dossier/mon-dossier.module').then( m => m.DossierPageModule),
          canActivate: [AuthGuard]
      
        },
        {
          path: 'ma-candidature',
          loadChildren: () => import('./ma-candidature/ma-candidature.module').then( m => m.CandidaturePageModule),    
          canActivate: [AuthGuard]
        },
        
        {
          path: 'sign-up',
          loadChildren: () => import('./sign-up/sign-up.module').then( m => m.SignUpPageModule)
        }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
